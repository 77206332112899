import { graphql, Link } from "gatsby";
import * as React from "react";
import * as style from "./index.module.scss";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
// import useInterval from "react-useinterval";
import { Helmet } from "react-helmet";

// markup
const IndexPage = ({
  data: {
    allFile: { edges: galleryImages },
  },
}) => {
  // const [numberOfGalleryImages, setNumberOfGalleryImages] = React.useState(4);
  // const [galleryImageNumber, setGalleryImageNumber] = React.useState(0);
  // const [currentImageStyle, setCurrentImageStyle] = React.useState({});

  // const updateImage = () => {
  //   setGalleryImageNumber((galleryImageNumber + 1) % numberOfGalleryImages);
  // };

  // React.useEffect(() => {
  //   setCurrentImageStyle({
  //     "--galleryImage": "url('/gallery" + (galleryImageNumber + 1) + ".jpeg')",
  //   });
  // }, [galleryImageNumber]);

  // useInterval(updateImage, 5000);

  const contentHeader = React.useRef();

  const scrollToMainContent = (e) => {
    contentHeader.current.scrollIntoView({ behavior: "smooth" });
  };

  const seo = {
    title: "The Backyard Brothers | Construction company",
    description:
      "From outdoor patios to fences, decks and everything in between, Tyler and Andrew Klassen bring your renovation dreams to life.",
    image: "/social_media.png",
    url: "https://backyardbrothers.ca/",
  };

  return (
    <div className={style.wrapper}>
      <Helmet>
        <title>{seo.title}</title>
        <link href="/favicon.ico" rel="icon" type="image/x-icon" />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta property="og:url" content={seo.url} />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />
      </Helmet>
      <header className={`${style.section} ${style.staticContent}`}>
        <Link to="/">
          <StaticImage src="../images/logo.png" alt="Logo" width={125} />
        </Link>
      </header>
      <section className={`${style.section}`}>
        <div className={style.mainHolder}>
          <div className={style.title}>
            <h1>The Backyard Brothers</h1>
            <a href="https://www.facebook.com/profile.php?id=100066607376652" rel="noreferrer nofollow" target="_blank">
              <StaticImage src="../images/fb.png" alt="Logo" width={175} class={style.fb} />
            </a>
          </div>
        </div>
      </section>
      <main className={`${style.section} ${style.staticContent}`}>
        <div>
          <h2 ref={contentHeader}>
            From outdoor patios to fences, decks and everything in between
          </h2>
          <div className={style.center}>
            <StaticImage
              src="../images/tools.png"
              alt="Tools"
              width={75}
              className={style.center}
            />
          </div>
          <p>
            Over the past 5 years Andrew and Tyler Klassen have built up
            knowledge in their respective trades in preparation to launch The
            Backyard Brothers. Andrew has worked doing concrete foundations,
            flatwork and renovation jobs, while Tyler has built up knowledge in
            general construction specializing in carpentry and remodelling. The
            Backyard Brothers are ready to take on your next home improvement
            within the Niagara region, whether thats a deck, patio, or anything
            you have in mind. We value quality and honesty in our work and look
            forward to collaborating with you.
          </p>
          <h3>Patios &amp; Hardscaping</h3>
          <p>
            Call <a href="tel:+9052467022">(905) 246-7022</a> or email{" "}
            <a href="mailto:backyardbrothersniagara@gmail.com">
              backyardbrothersniagara@gmail.com
            </a>
            .
          </p>
          <h3>Decks, Fences &amp; All Other Inquiries</h3>
          <p>
            Call <a href="tel:+9059321129">(905) 932-1129</a> or email{" "}
            <a href="mailto:backyardbrothersniagara@gmail.com">
              backyardbrothersniagara@gmail.com
            </a>
            .
          </p>
          <h2>Gallery</h2>
          <div className={style.photoGallery}>
            {console.log("hi" + process.env.GOOGLE_DRIVE_GALLERY_FOLDER_ID)}
            {galleryImages.map((image) => (
              <GatsbyImage
                key={image.node.id}
                image={getImage(image.node)}
                alt="Gallery image"
                className={style.photo}
              />
            ))}
          </div>
          <h2>
            <abbr title="Established">Est.</abbr> 1998
          </h2>
          <StaticImage src="../images/brothers.jpeg" alt="Childhood photo" />
        </div>
      </main>
      <footer>&copy; 2022 The Backyard Brothers. All rights reserved.</footer>
    </div>
  );
};

export const query = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "gallery" } }) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
    }
  }
`;

export default IndexPage;
